textarea {
    border: none;
    outline: none;
}

.mentions__input {
    /*font-family: monospace !important;*/
}

.mentions .li:hover {
  background-color: transparent;
}

.mentions--singleLine .mentions__control {
    display: inline-block;
    width: 130px;
}

.mentions--singleLine .mentions__highlighter {
    padding: 1px;
    border: 2px inset transparent;
}

.mentions--singleLine .mentions__input {
    padding: 1px;
    border: 2px inset;
}

.mentions--multiLine .mentions__control {
}

.mentions--multiLine .mentions__highlighter {
    border: 1px solid transparent;
    min-height: 63px;
}

.mentions--multiLine .mentions__input {
    outline: 0;
}

.mentions__suggestions__list {
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;

}

.mentions__suggestions__item {
    padding: 8px 8px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    min-width: 150px;
}

.mentions__suggestions__item--focused {
    background-color: #eeeeee;
}

.mentions__mention {
    position: relative;
    z-index: 1;
    color: blue;
    text-shadow: 1px 1px 1px white, 1px -1px 1px white, -1px 1px 1px white,
    -1px -1px 1px white;
    text-decoration: underline;
    pointer-events: none;
}

.mentions strong {
    background-color: rgba(0,80,0,0.1);
}